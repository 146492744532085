import React, { Suspense, useEffect, useState, lazy, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import LoadingPage from './Pages/PcPages/LoadingPage/LoadingPage';
import { debounce } from './Utils';
import useOrientation from './Hooks/useOrientation';
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n/config";
import { use } from 'i18next';

import { FpjsProvider /*, FingerprintJSPro */ } from '@fingerprintjs/fingerprintjs-pro-react'

const RootComponent = () => {

  useOrientation();
  const [isMobile, setIsMobile] = useState(false);

  // 檢測設備類型
  const detectDeviceType = useCallback(() => {
    const isMobileDevice = /Mobi/i.test(navigator.userAgent);
    if (window.innerWidth < 768 && isMobileDevice) {
      setIsMobile(true);
    }
  },[]);

  useEffect(() => {
    detectDeviceType();
    window.addEventListener("resize", detectDeviceType);
    return () => {
      window.removeEventListener("resize", detectDeviceType);
    };
  }, [detectDeviceType]);

  const App = isMobile ? lazy(() => import("./AppMobile")) : lazy(() => import("./App"));

  return (
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<LoadingPage />}>
            <FpjsProvider
              loadOptions={{
                apiKey: 'SubhMxSiHgRMQ1Ulkz1i',
                // region: 'eu',
                // endpoint: ['metrics.yourwebsite.com', FingerprintJSPro.defaultEndpoint],
                // scriptUrlPattern: ['metrics.yourwebsite.com/agent-path', FingerprintJSPro.defaultScriptUrlPattern],
              }}
            >
              <App />
            </FpjsProvider>
          </Suspense>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>
  );

}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RootComponent />);

reportWebVitals();
