import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SiteMessageState {
  unreadMessagesCount: number;
}

export const initialState: SiteMessageState = {
  unreadMessagesCount: 0,
};

export const SiteMessageSlice = createSlice({
  name: 'SiteMessage',
  initialState: initialState,
  reducers: {
    setSiteMessageData: (state, action: PayloadAction<number>) => {
      state.unreadMessagesCount = action.payload;
    },
  }
});

export const { setSiteMessageData } = SiteMessageSlice.actions;

export default SiteMessageSlice.reducer;